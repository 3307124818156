/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Article, Hero, I18Link, Quote } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/header-wiredup-initiative-management.jpg";
import Workplan_section from "../../images/wiredup-initiative-workplan.png";
import Value_driver_trees_section from "../../images/wiredup-initiative-value-driver.png";
import Initiativessection from "../../images/wiredup-initiative-initiatives.png";
import Investment_evaluation_and_cash_flows_section from "../../images/wiredup-initiative-investment-evaluation.png";
import Emissions_management_icon from "../../images/Emissions-management_icon.png";
import Workplan_icon from "../../images/Workplan-icon.png";
import Value_driver_tree_icon from "../../images/Value_driver_tree_icon.png";
import Investment_evaluation_icon from "../../images/Investment_evaluation_icon.png";
import forecasts_icon from "../../images/forecasts-icon.png";
import Stage_gated_approval_workflow_icon from "../../images/Stage_gated_approval_workflow_icon.png";
import Risk_tracking_icon from "../../images/Risk_tracking_icon.png";
import Benefit_realisation_icon from "../../images/Benefit_realisation_icon.png";
import Business_modelling_icon from "../../images/Business modelling.png";
import Initiatives from "../../images/Initiatives.png";
import uparrow from "../../images/top-button.svg";
import commaimg from "../../images/Quotation_mark.png";
import leftIcon from "../../images/Left Button.svg";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1440,
  height: 720,
};

const Workplan_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Workplan_section },
      srcSet: Workplan_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
  id: "test",
};

const Value_driver_trees_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Value_driver_trees_section },
      srcSet: Value_driver_trees_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const Initiativessection_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Initiativessection },
      srcSet: Initiativessection,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const Investment_evaluation_and_cash_flows_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Investment_evaluation_and_cash_flows_section },
      srcSet: Investment_evaluation_and_cash_flows_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const comma = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 600px) 600px, 100vw",
      src: { commaimg },
      srcSet: commaimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 600,
  height: 600,
};
const persondetails = {
  company: null,
  country: "International Airline",
  image: comma,
  jobTitle: "Finance & Reporting Analyst",
  link: "/people/skipp-williamson-et",
  linkedin: "https://www.linkedin.com/in/skippwilliamson/",
  name: "International Airline",
  quote:
    "WiredUp is a great project management reporting tool which allows us to identify the status and value of each initiative in our ideas pipeline through the simple click of a button.",
  sector: "Infrastructure",
};
function clikinguparrow() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  return;
}
const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: {},
    graphCmsSectorPage: sectorPage,
  },
}) => {
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={
        "idea management software, business process improvement software"
      }
      title={" Idea Management Software by WiredUp | Invitative Management"}
      description={
        "WiredUp’s idea management software drives your change initiatives to completion and helps your organisation achieve a lasting impact."
      }
    >
      <Hero
        title="Idea management"
        subheading=""
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="py-60px lg:grid-cols-4 grid-cols-1 p-10px page-grid row md:w-5/6 w-auto m-auto">
        <a
          href="#drive_initiatives"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Initiatives}
                  alt="centralise- improvement-initiative"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4">Initiatives</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Create and manage change initiatives with a robust and proven
                implementation process to achieve desired benefits
              </p>
            </div>
          </div>
        </a>
        <a
          href="#investment_evaluation_and_cash_flows"
          className="ipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Investment_evaluation_icon}
                  alt="metrics-for-evaluating-financial-performance"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4">Investment evaluation and cash flows</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Evaluate project investment, calculate NPV, IRR and payback
                periods and then track cash flows over the entire lifecycle of
                your initiatives
              </p>
            </div>
          </div>
        </a>
        <a
          href="#Value_driver_trees"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Value_driver_tree_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4">Value driver trees</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Utilise value driver trees to model and analyse how external
                value drivers, measured by KPIs, impact your initiatives
              </p>
            </div>
          </div>
        </a>
        <a
          href="#create_workplans"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Workplan_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4">Workplans</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Plan and assign initiative implementation activities, while
                using Gantt charts to easily track dependencies and progress
                team
              </p>
            </div>
          </div>
        </a>
      </div>

      <Quote
        quote={`"WiredUp is a great project management reporting tool which allows us to identify the status and value of each initiative in our ideas pipeline through the simple click of a button."`}
        profile={persondetails}
        withoutqote="YES"
      />
      <div id="drive_initiatives">
        <Article
          side="right"
          id={""}
          image={Initiativessection_img}
          alt={"centralise- improvement-initiative"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Centralise your improvement initiatives into one integrated digital transformation platform that streamlines programs and helps empower your teams to work collaboratively towards the same objectives. Experience the full lifecycle management of your financial and non-financial improvement ideas, from concept to lasting value delivery. Our platform enables you to:",
              heading: [],
            },
          ]}
          Articletitle="Implemenent successful improvement initiatives "
          listing={[
            "Evaluate and prioritise project investment and value creation with cash flow management",
            "Visualise key value drivers to identify and optimise their performance",
            "Track risks and proactive mitigate them",
            "Plan, track and drive projects with interactive workplans and Gantt charts",
            "Calculate and measure benefits generated by your improvement initiatives",
            "Enter forecast values to evaluate how realised benefits align to your plan",
            "Manage initiative progress, from evaluation to lock-in with stage-gated approval workflows",
          ]}
          afterlisting="WiredUp helps you drive initiatives to completion and achieve your strategic goals."
        />
      </div>
      <div id="investment_evaluation_and_cash_flows">
        <Article
          side="left"
          id={""}
          image={Investment_evaluation_and_cash_flows_section_img}
          alt={"metrics-for-evaluating-financial-performance"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Drive the success of your initiatives by evaluating their financial viability before implementation. WiredUp’s cash flows and investment evaluation module allows you to:",
              heading: [],
            },
          ]}
          Articletitle="Measure initative success with our NPV calculator"
          listing={[
            "Calculate essential financial metrics such as Net Present Value (NPV), Internal Rate of Return(IRR) and payback period",
            "Record and track current and projected cash flows for the entire initiative lifecycle",
          ]}
          afterlisting="Make informed, data-driven decisions to ensure the suitability and future success of every initiative."
        />
      </div>
      <div id="Value_driver_trees">
        <Article
          side="right"
          id={""}
          image={Value_driver_trees_section_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Gain valuable insights into your initiative by using value driver trees (VDTs) to highlight value generators and identify bottlenecks or inefficient stages. WiredUp seamlessly integrates KPIs with VDTs so you can compare real-time data against baselines and targets, giving you a comprehensive and dynamic view of every process. Our VDT feature offers:",
              heading: [],
            },
          ]}
          Articletitle="See how initiatives impact key value drivers"
          listing={[
            "Modelling of key value driver process to help track the progress of improvement implementation",
            "Real-time visibility on processes to drive informed decision-making and proactive adjustments",
            "Robust tracking of financial and non-financial metrics related to your initiatives, giving you an accurate view of their impact",
          ]}
          afterlisting="Get real-time modelling and tracking of initiative value driver processes, their improvement progress and their impact."
        />
      </div>
      <div id="create_workplans">
        <Article
          side="left"
          id={""}
          image={Workplan_section_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Execute your initiatives with ease by efficiently planning every step with workplans and easily assigning responsibilities to your team. WiredUp gives you clear visibility into initiative progress and actions specific to them, so you can plan each implementation to ensure success. Our workplans module offers:",
              heading: [],
            },
          ]}
          Articletitle="Plan and track initatitve progress with Gantt Charts"
          listing={[
            "Simplified initiative implementation and planning",
            "Responsibility assignment to foster collaboration and accountability in your team",
            "Effective monitoring and decision-making through comprehensive progress tracking",
            "User-friendly activity and task management that allows you to add activities from Gantt charts",
          ]}
          afterlisting="Easily create workplans to streamline initiative implementation and align your team to work towards the same objectives."
        />
      </div>
      <div className="bg-white-1 p-35px">
        <center className="mt-20px">
          <h3 className="h3">
            Explore the Initiatives module’s additional features
          </h3>
        </center>
        <div className="page-grid md:grid-cols-2 grid-cols-1">
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={forecasts_icon}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Forecasting</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                WiredUp’s enables you to effortlessly forecast financial and
                non-financial benefits. Enter or import forecasted values for
                real-time reporting on current or projected benefit realisation
                and gain deeper insights into the impact of each initiative.
              </p>
            </div>
          </div>
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={Risk_tracking_icon}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Risk tracking</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Efficiently manage risks with WiredUp’s integrated risk tracking
                feature. Customise risk impacts, severities and categories to
                align with your organisations needs and record initiative risks,
                mitigation options and assign owners for proactive and effective
                risk management.
              </p>
            </div>
          </div>
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={Stage_gated_approval_workflow_icon}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Stage-gated approval workflow</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Achieve greater control over managing your initiatives from
                start to finish. WiredUp’s streamlines your approval process
                creation of custom workflows, checklists and approvers to ensure
                a controlled and structured progression of initiatives along the
                pipeline.
              </p>
            </div>
          </div>
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={Benefit_realisation_icon}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Benefit realisation</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Unleash potential in your initiatives with WiredUp’s integrated
                benefit realisation. Through real-time calculations based on
                actual performance metrics from KPIs, you’ll have instant
                visibility into both financial and non-financial benefit
                realisation.
              </p>
            </div>
          </div>
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={Stage_gated_approval_workflow_icon}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Reporting</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Benefit from out-of-the-box reporting capabilities that provide
                valuable insights into your data. For enhanced reporting
                flexibility, seamlessly embed Microsoft Power BI reports into
                WiredUp, tailoring the reporting experience to your specific
                needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-35px">
        <center>
          <h3 className="h3">Add-on modules</h3>
          <br></br>
          <h5 className="h4">
            The following add-on features are available in this plan
          </h5>
        </center>
        <div className="page-grid md:grid-cols-2 grid-cols-1 p-30px">
          <div className="p-20px m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a href="/en/wiredup-diagnostic-and-analysis/">
                <img
                  src={Business_modelling_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup-diagnostic-and-analysis/">
                <h4 className="h4">Value driver diagnostic</h4>
              </a>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Make WiredUp even more powerful and efficient with our value
                driver diagnostic add-on. It empowers you to model key process
                or even your whole business with value driver trees to identify
                bottlenecks, inefficient operations and key value generators
                while allowing for sensitivity, attribution and variance
                analysis.
              </p>
              <a href="/en/wiredup-diagnostic-and-analysis/">
                <p className="block pt-40px read-more md:w-desktop-4-cols w-auto m-auto">
                  Discover more
                </p>
              </a>
            </div>
          </div>
          <div className="p-20px m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a href="/en/wiredup-esg-management-software/">
                <img
                  src={Emissions_management_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup-esg-management-software/">
                <h4 className="h4">Emissions management</h4>
              </a>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Take control of your decarbonisation program with WiredUp’s
                emissions management add-on. Manage your organisation’s emission
                reduction programs from a central source. Set targets, define
                emissions factors, conduct scenario planning and forecasting and
                review the impact and cost-effectiveness of programs with an
                integrated marginal abatement cost curve (MACC) analysis.
              </p>
              <a href="/en/wiredup-esg-management-software/">
                <p className="block pt-40px read-more md:w-desktop-4-cols w-auto m-auto">
                  Discover more
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
        Find out how WiredUp can help your organisation make a lasting impact{" "}
        <b>
          <br></br>
          <center>
            <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
              Schedule a demo
            </a>
          </center>
        </b>
      </p>
      <section className="py-section page-grid max-width z-10 gap-y-20px">
        <I18Link
          to={"/wiredup-change-management-software"}
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to WiredUp
        </I18Link>
      </section>
      <br></br>
      <button id="scrollToTopBtn" onClick={clikinguparrow}>
        <img src={uparrow} className="uparrow_wiredup"></img>
        {/* <div className="float-button-arrow bg-no-repeat bg-28px min-h-28px w-30px h-30px"></div> */}
      </button>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
